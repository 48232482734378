import React, {useState, useEffect} from 'react'
import AddEvidence from './AddEvidence'
import isJwtTokenExpired from 'jwt-check-expiry'
import Messages from '../../../Message/Message'
import {useHistory, useLocation} from 'react-router-dom'
import $ from 'jquery'
import {
  Form,
  Col,
  Select,
  Row,
  Input,
  Button,
  Modal,
  Popconfirm,
  Table,
  message,
} from 'antd'
import {PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons'

const baseUrl = process.env.REACT_APP_BASE_URL
function ProficiencyLevel(props) {
  console.log(props)
  var user = JSON.parse(localStorage.getItem('user'))
  let headers = {'Content-Type': 'application/json'}
  const token = user.token

  var RosterID = ''
  var isAdvisory = false
  const location = useLocation()
  const history = useHistory()
  const [updateTimeLine, setUpdateTimeLine] = useState('')
  const [evidenceId, setEvidenceId] = useState(0)
  const [evidence, setEvidence] = useState({})
  const [currentDate, setCurrentDate] = useState(new Date())
  const [disable, setDisable] = useState(false)
  const [modifiedOn, setModifiedOn] = useState('')
  const [modifiedBy, setModifiedBy] = useState('')
  const [rotationDateRangeData, setRotationDateRangeData] = useState()
  const [progressionEditable, setProgressionEditable] = useState(true)
  const [showAddedOn, setShowAddedOn] = useState(false)

  if (history.location.state && history.location.state.rosterId) {
    RosterID = history.location.state.rosterId
    isAdvisory = history.location.state.isAdvisory
  } else {
    RosterID = props.data.rosterId
  }
  //RosterID = 10;
  if (props.data) {
    var {
      studentId,
      studentName,
      subject,
      phase,
      categoryId,
      category,
      learningTarget,
      status,
    } = props.data
  } else if (location.state) {
    var {
      studentId,
      studentName,
      subject,
      phase,
      categoryId,
      category,
      learningTarget,
      status,
    } = location.state
  } else {
    var {
      studentId,
      studentName,
      subject,
      phase,
      categoryId,
      category,
      learningTarget,
    } = props.location.state
  }

  var initialValues = {
    isAdvisory: isAdvisory ? isAdvisory : false,
    studentId: studentId,
    studentName: studentName,
    subjectName: subject,
    phaseName: phase,
    categoryId: categoryId,
    categoryName: category,
    learningTarget: learningTarget,
    iCanStatement: '',
    description: 'string',
    proficiency: 0,
    grade: 'string',
    notes: 'string',
    teacherId: 0,
    status: status,
    showContent: true,
  }
  console.log('props', props)
  const {getFieldDecorator} = props.form
  const {TextArea} = Input
  const {Option} = Select

  var isHeader = true
  const [inputs, setInputs] = useState(initialValues)
  const [modalContent, setModalContent] = useState({
    modalName: '',
    modalData: '',
  })
  const [visible, setVisible] = React.useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [filterTable, setFilterTable] = useState([])
  const [proficiencyStatusList, setProficiencyStatusList] = useState([
    'P',
    'IP',
    ' ',
  ])
  var user = JSON.parse(localStorage.getItem('user'))

  if (user) {
    var isExpired = isJwtTokenExpired(user.token)
    if (isExpired) {
      message.error(`${Messages.unHandledErrorMsg}`)
      history.replace({pathname: '/', state: {isActive: true}})
    }
  } else {
    message.error(`${Messages.unHandledErrorMsg}`)
    history.replace({pathname: '/', state: {isActive: true}})
  }
  const modalData = (modalName, modalData) => {
    setModalContent({modalName, modalData})
  }

  const columns = [
    {
      title: 'Evidence',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) => (
        <>
          <span>
            {record.description ? (
              record.description.length < 90 ? (
                record.description
              ) : (
                <>
                  {record.description.substring(0, 89)} ...{' '}
                  <button
                    onClick={() => modalData('Description', record.description)}
                    class="btn btn-outline-link"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Read more
                  </button>
                </>
              )
            ) : (
              ''
            )}
          </span>
        </>
      ),
    },
    {
      title: 'Proficiency',
      dataIndex: 'proficiency',
      render: (text, record) => <span>{record.proficiency} </span>,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      render: (text, record) => <span>{record.grade} </span>,
    },
    {
      title: 'Date',
      dataIndex: 'addedOn',
      render: (text, record) => (
        <span>
          {new Date(record.addedOn).toLocaleString('en-US').split(',')[0]}{' '}
        </span>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => (
        <>
          <span>
            {record.notes ? (
              record.notes.length < 90 ? (
                record.notes
              ) : (
                <>
                  {record.notes.substring(0, 89)} ...{' '}
                  <button
                    onClick={() => modalData('Notes', record.notes)}
                    class="btn btn-outline-link"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Read more
                  </button>
                </>
              )
            ) : (
              ''
            )}
          </span>
        </>
      ),
    },
    {
      title: 'Teacher',
      dataIndex: 'teacherName',
      render: (text, record) => <span>{record.teacherName} </span>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id, record) => (
        <>
          {user.role === 'SuperAdmin' || user.role === 'Admin' ? (
            <Button type="primary" onClick={() => editEvidence(id)}>
              <EditOutlined />
            </Button>
          ) : (
            ''
          )}
          {user.role === 'Teacher' &&
          record.addedOn.slice(0, 10) == currentDate.slice(0, 10) ? (
            <Button type="primary" onClick={() => editEvidence(id)}>
              <EditOutlined />
            </Button>
          ) : (
            ''
          )}
          {user.role === 'SuperAdmin' || user.role === 'Admin' ? (
            <Popconfirm
              title="Are you sure you want to delete this evidence ?"
              onConfirm={() => deleteEvidence(id)}>
              <button className="delete_btn">
                <DeleteOutlined />
              </button>
            </Popconfirm>
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  const columnsForStudent = [
    {
      title: 'Evidence',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (text, record) => (
        <>
          <span>
            {record.description ? (
              record.description.length < 90 ? (
                record.description
              ) : (
                <>
                  {record.description.substring(0, 89)} ...{' '}
                  <button
                    onClick={() => modalData('Description', record.description)}
                    class="btn btn-outline-link"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Read more
                  </button>
                </>
              )
            ) : (
              ''
            )}
          </span>
        </>
      ),
    },
    {
      title: 'Proficiency',
      dataIndex: 'proficiency',
      render: (text, record) => <span>{record.proficiency} </span>,
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      render: (text, record) => <span>{record.grade} </span>,
    },
    {
      title: 'Date',
      dataIndex: 'addedOn',
      render: (text, record) => (
        <span>
          {new Date(record.addedOn).toLocaleString('en-US').split(',')[0]}{' '}
        </span>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => (
        <>
          <span>
            {record.notes ? (
              record.notes.length < 90 ? (
                record.notes
              ) : (
                <>
                  {record.notes.substring(0, 89)} ...{' '}
                  <button
                    onClick={() => modalData('Notes', record.notes)}
                    class="btn btn-outline-link"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Read more
                  </button>
                </>
              )
            ) : (
              ''
            )}
          </span>
        </>
      ),
    },
    {
      title: 'Teacher',
      dataIndex: 'teacherName',
      render: (text, record) => <span>{record.teacherName} </span>,
    },
  ]

  const editEvidence = async id => {
    setEvidenceId(id)
    let headers = {'Content-Type': 'application/json'}
    const token = user.token
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    var res = await fetch(
      `${baseUrl}/LearningTargetEvidences/Get?id=${parseInt(id)}`,
      {headers},
    )
    let data = await res.json()
    console.log(data)
    setEvidence(data)
    showModal()
    getAllEvidence()
  }

  const deleteEvidence = async id => {
    let headers = {'Content-Type': 'application/json'}
    const res = await fetch(
      `${baseUrl}/LearningTargetEvidences/Delete?id=${id}`,
      {method: 'POST', headers},
    )
    getAllEvidence()
  }

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setEvidenceId(0)
    setVisible(false)
  }

  const fetchICanStatement = async () => {
    var res
    try {
      let headers = {'Content-Type': 'application/json'}
      const token = user.token
      console.log('token', token)
      if (token) {
        headers['Authorization'] = `Bearer ${token}`
      }
      res = await fetch(
        `${baseUrl}/RosterLearningTargets/GetIcanStatement?categoryId=${parseInt(
          inputs.categoryId,
        )}&learningTarget=${parseInt(
          inputs.learningTarget,
        )}&studentId=${parseInt(inputs.studentId)}`,
        {headers},
      )
      let data = await res.json()
      console.log('data1', data)
      setInputs({...inputs, iCanStatement: data.data})
    } catch (err) {
      console.log(err)
    }
  }

  const fetchData = async () => {
    var res
    try {
      let headers = {'Content-Type': 'application/json'}
      const token = user.token
      console.log('token', token)
      if (token) {
        headers['Authorization'] = `Bearer ${token}`
      }
      res = await fetch(
        `${baseUrl}/RosterLearningTargets/GetByRosterIdAndLearningTarget?rosterId=${parseInt(
          RosterID,
        )}&learningTarget=${inputs.learningTarget}`,
        {headers},
      )
      let data = await res.json()
      console.log('data1', data)
      setInputs({...inputs, iCanStatement: data.data.iCanStatement})
    } catch (err) {
      console.log(err)
    }
  }

  const getAllEvidence = async () => {
    var res
    try {
      var currentDate = new Date()
      var month = currentDate.getMonth() + 1
      var cDdate =
        currentDate.getFullYear() +
        '-' +
        ('0' + month).slice(-2) +
        '-' +
        ('0' + currentDate.getDate()).slice(-2) +
        'T' +
        currentDate.getHours() +
        ':' +
        currentDate.getMinutes() +
        ':' +
        currentDate.getSeconds() +
        '.' +
        currentDate.getMilliseconds()
      setCurrentDate(cDdate)
      let headers = {'Content-Type': 'application/json'}
      const token = user.token
      if (token) {
        headers['Authorization'] = `Bearer ${token}`
      }
      res = await fetch(
        `${baseUrl}/LearningTargetEvidences/GetAll?studentId=${parseInt(
          inputs.studentId,
        )}&rosterId=${parseInt(RosterID)}&categoryId=${parseInt(
          inputs.categoryId,
        )}&learningTarget=${parseInt(inputs.learningTarget)}`,
        {headers},
      )
      let data = await res.json()
      console.log('data', data.data)
      setFilterTable([...data.data])
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    $('.anticon.anticon-menu-fold.trigger.layout-trigger.header-toggle').click()
    headers['Authorization'] = `Bearer ${token}`
    var l = JSON.parse(localStorage.getItem('user'))
    console.log(l)
    fetch(
      `${process.env.REACT_APP_BASE_URL}/RosterLearningTargets/GetIcanStatement?categoryId=${inputs.categoryId}&learningTarget=${inputs.learningTarget}&studentId=1`,
    )
      .then(res => res.json())
      .then(data => {
        if (data.data !== null) {
          setInputs({...inputs, iCanStatement: data.data})
        }
      })
    !isExpired &&
      inputs.categoryId &&
      (inputs.isAdvisory || !parseInt(RosterID)) &&
      fetchICanStatement()
    inputs.categoryId && getAllEvidence()
    if (props.data) {
      setUpdateTimeLine(1)
    }
    {
      l.role !== 'Student' &&
        fetch(
          `${baseUrl}/LearningTargets/GetByCategoryIdLearningTargetStudentId?studentId=${parseInt(
            location.state.studentId,
          )}&categoryId=${parseInt(
            location.state.categoryId != null
              ? location.state.categoryId
              : props.data.categoryId,
          )}&learningTarget=${parseInt(
            location.state.learningTarget != null
              ? location.state.learningTarget
              : props.data.learningTarget,
          )}`,
          {
            headers,
          },
        )
          .then(res => res.json())
          .then(data => {
            console.log(data.data)
            if (data.data.imported === true && data.data.progress === 'P') {
              setDisable(true)
            }

            if (props.data && props.data.modifiedOn !== null) {
              const dateString = props.data.modifiedOn.slice(0, 10)
              const dateObject = new Date(dateString)
              const formattedDate =
                dateObject.getMonth() +
                1 +
                '-' +
                dateObject.getDate() +
                '-' +
                dateObject.getFullYear()
              setModifiedOn(formattedDate.toString())
              setModifiedBy(props.data.modifiedBy)
            } else if (data.data.modifyOn !== '0001-01-01T00:00:00') {
              const dateStringAddedOn = data.data.addedOn.slice(0, 10)
              const dateStringModifiedOn = data.data.modifyOn.slice(0, 10)

              const dateObjectAddedOn = new Date(dateStringAddedOn)
              const dateObjectModifiedOn = new Date(dateStringModifiedOn)

              const formattedDateAddedOn =
                dateObjectAddedOn.getMonth() +
                1 +
                '-' +
                dateObjectAddedOn.getDate() +
                '-' +
                dateObjectAddedOn.getFullYear()

              const formattedDateModifiedOn =
                dateObjectModifiedOn.getMonth() +
                1 +
                '-' +
                dateObjectModifiedOn.getDate() +
                '-' +
                dateObjectModifiedOn.getFullYear()

              console.log(formattedDateAddedOn, formattedDateModifiedOn)
              if (formattedDateAddedOn === formattedDateModifiedOn) {
                console.log('Equal', data.data)
                setModifiedOn(formattedDateModifiedOn.toString())
                setShowAddedOn(true)
              } else {
                setModifiedOn(formattedDateModifiedOn.toString())
              }
              setModifiedBy(data.data.completedTarget)
              getRotationDateRange(data.data.rosterId)
            }
          })
    }

    {
      l.role === 'Student' &&
        fetch(
          `${baseUrl}/LearningTargets/GetByCategoryIdLearningTargetStudentId?studentId=${parseInt(
            l.userId,
          )}&categoryId=${parseInt(
            props.data.categoryId,
          )}&learningTarget=${parseInt(props.data.learningTarget)}`,
          {
            headers,
          },
        )
          .then(res => res.json())
          .then(data => {
            console.log(data)
            const dateString = data.data.modifyOn.slice(0, 10)
            const dateObject = new Date(dateString)
            const formattedDate =
              dateObject.getMonth() +
              1 +
              '-' +
              dateObject.getDate() +
              '-' +
              dateObject.getFullYear()
            setModifiedBy(data.data.completedTarget)
            setModifiedOn(formattedDate)
            if (data.data.imported === true && data.data.progress === 'P') {
              setDisable(true)
            }
          })
    }
  }, [])

  const getRotationDateRange = async rosterId => {
    console.log(rosterId)
    fetch(`${baseUrl + '/Rosters/Get'}?id=${parseInt(rosterId)}&schoolId=1`, {
      headers,
    })
      .then(res => res.json())
      .then(async result => {
        console.log(result.data.Roster)
        if (result.data.Roster.advisory === false) {
          return await fetch(
            `${baseUrl}/RotationDateRanges/Get?id=${parseInt(
              result.data.Roster.dateRangeId,
            )}&schoolId=1`,
            {headers},
          )
            .then(res => res.json())
            .then(data => {
              console.log(data.data)
              setRotationDateRangeData(data.data)
              checkIfExpired(data.data)
            })
        }
      })
  }

  const checkIfExpired = async data => {
    currentDate.setHours(0, 0, 0, 0)
    var startDate = new Date(data.startDate)
    startDate.setHours(0, 0, 0, 0)
    var endDate = new Date(data.endDate)
    endDate.setHours(0, 0, 0, 0)
    if (currentDate >= startDate && currentDate <= endDate) {
      console.log('The target date falls within the range.')
    } else {
      console.log('The target date is outside the range.')
      setProgressionEditable(false)
    }
  }

  const handleGetAllEvidence = () => {
    getAllEvidence()
  }

  const handleSubmitUpdate = e => {
    e.preventDefault()
    const token = user.token
    props.form.validateFields((err, values) => {
      if (!err) {
        let data = {
          studentId: inputs.studentId,
          categoryId: inputs.categoryId,
          learningTarget: inputs.learningTarget,
          status: inputs.status,
          rosterId: RosterID,
        }
        const requestMetadata = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
        fetch(`${baseUrl}/LearningTargets/SaveProficiency`, requestMetadata)
          .then(res => res.json())
          .then(data => {
            if (data.data === null) {
              message.warning(
                'Proficiency cannot be changed as it was set in previous Session.',
              )
            }
            if (data.statusCode === 200 && data.data != null) {
              message.success('Status updated successfully !!')
              updateTimeLine && props.updateTimeLineData()
              fetchUpdatedDate(data)
            } else if (data.statusCode === 208) {
            } else {
              updateTimeLine && props.updateTimeLineData()
            }
          })
      }
    })
  }

  const fetchUpdatedDate = data => {
    console.log(data.data)
    const dateString = data.data.modifyOn.slice(0, 10)
    const dateObject = new Date(dateString)
    const formattedDateModifiedOn =
      dateObject.getMonth() +
      1 +
      '-' +
      dateObject.getDate() +
      '-' +
      dateObject.getFullYear()
    setModifiedOn(formattedDateModifiedOn)
    var user = JSON.parse(localStorage.getItem('user'))
    setModifiedBy(user.name)
  }

  const isVisible = () => {
    if (
      user.role.toLowerCase() === 'superadmin' ||
      user.role.toLowerCase() === 'admin' ||
      user.role.toLowerCase() === 'teacher'
    ) {
      return true
    }
    return false
  }

  const isVisibleAdminsOnly = () => {
    if (
      user.role.toLowerCase() === 'superadmin' ||
      user.role.toLowerCase() === 'admin' ||
      user.role.toLowerCase() === 'teacher' // Code made on 1/9/24 for task LPTS-137 that grants access to teachers to change the proficiency from 'P'
    ) {
      return true
    }
    return false
  }

  return (
    <>
      <ModalContent modal={modalContent} />
      <div className="dash-bg-white">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={16} lg={8}>
            <div className="section-top-heading">
              <h3
                style={{
                  color: '#0C1362',
                  fontWeight: '600',
                  fontSize: '20px',
                }}>
                {' '}
                Proficiency{' '}
              </h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6}>
            <Form.Item>
              <div
                style={{
                  textAlign: 'center',
                  padding: '8px',
                  borderRadius: '4px',
                  backgroundColor: '#faad14',
                }}>
                <label style={{color: '#fff', fontWeight: '500'}}>
                  Learning Target :
                  <span style={{color: '#fff', fontWeight: '500'}}>
                    {' '}
                    {inputs.learningTarget}
                  </span>
                </label>
              </div>
            </Form.Item>
          </Col>
          {inputs.showContent ? (
            <Col xs={24} sm={24} md={24} lg={10}>
              <Row gutter={[16, 16]}>
                <div className="d-flex status-label-row">
                  {(status !== 'P' && isVisible()) ||
                  (status == 'P' && isVisibleAdminsOnly()) ? (
                    <Form.Item
                      label="Status"
                      name="status"
                      className="status-label  ">
                      {getFieldDecorator('status', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select status!',
                          },
                        ],
                        initialValue: status,
                      })(
                        <Select
                          value="Select"
                          onChange={value =>
                            setInputs({...inputs, status: value})
                          }
                          name="status"
                          style={{width: '100%'}}
                          disabled={disable}>
                          {proficiencyStatusList &&
                            proficiencyStatusList.map((status, key) => (
                              <Option
                                key={key}
                                value={status}
                                className="proficiency-dropdown-select">
                                {' '}
                                {status}
                              </Option>
                            ))}
                        </Select>,
                      )}
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <label className="text-label">
                        Status :
                        <span style={{lineHeight: 'normal'}}>{status}</span>
                      </label>
                    </Form.Item>
                  )}
                  {((status !== 'P' && isVisible()) ||
                    (status == 'P' && isVisibleAdminsOnly())) &&
                  progressionEditable ? (
                    <Button
                      type="primary"
                      onClick={handleSubmitUpdate}
                      htmlType="submit">
                      Update
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Row>
            </Col>
          ) : (
            ''
          )}
        </Row>
        {modifiedBy && modifiedOn && (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="text-end">
                {' '}
                {showAddedOn ? (
                  <b>Added by/on: </b>
                ) : (
                  <b>Updated by/on: </b>
                )}{' '}
                {modifiedBy} {modifiedOn}
              </div>
            </Col>
          </Row>
        )}
      </div>

      <Form
        style={{margin: '15px 0'}}
        name="basic"
        initialValues={{remember: true}}
        autoComplete="off">
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item>
              <label className="text-label">
                Student :<span> {inputs.studentName}</span>
              </label>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item>
              <label className="text-label">
                Subject :<span> {inputs.subjectName}</span>
              </label>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item>
              <label className="text-label">
                Phase :<span> {inputs.phaseName}</span>
              </label>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={6}>
            <Form.Item>
              <label className="text-label">Category :</label>
              <span
                style={{
                  lineHeight: 'normal',
                  color: '#333',
                  fontSize: '14px',
                  fontWeight: '600',
                }}>
                {inputs.categoryName}
              </span>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={18} lg={24}>
            <Form.Item>
              <div style={{width: '100%', display: 'flex', margin: '10px 0'}}>
                <label className="text-label" style={{width: '160px'}}>
                  I Can Statement :
                </label>
                <span
                  style={{
                    lineHeight: 'normal',
                    color: '#333',
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '0 35px 0 0',
                  }}>
                  {inputs.iCanStatement}
                </span>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row style={{margin: '25px 0'}}>
        <Form>
          <Col sm={12} md={12} lg={12}></Col>
          {isVisible() ? (
            <Col sm={12} md={12} lg={12}>
              <div className="btn-level " style={{textAlign: 'right'}}>
                <Button
                  className="Add-btn-top"
                  type="primary"
                  onClick={showModal}>
                  <PlusOutlined />
                  Add Evidence
                </Button>

                {evidenceId === 0 && (
                  <Modal
                    title="Add Evidence"
                    visible={visible}
                    footer={null}
                    onCancel={handleCancel}>
                    <AddEvidence
                      studentId={inputs.studentId}
                      categoryId={inputs.categoryId}
                      learningTarget={inputs.learningTarget}
                      rosterId={RosterID}
                      evidenceId={evidenceId}
                      handleGetAllEvidence={handleGetAllEvidence}
                    />
                  </Modal>
                )}

                {evidenceId !== 0 && (
                  <Modal
                    title="Edit Evidence"
                    visible={visible}
                    footer={null}
                    onCancel={handleCancel}>
                    <AddEvidence
                      evidence={evidence}
                      evidenceId={evidenceId}
                      studentId={inputs.studentId}
                      categoryId={inputs.categoryId}
                      learningTarget={inputs.learningTarget}
                      rosterId={RosterID}
                      handleGetAllEvidence={handleGetAllEvidence}
                    />
                  </Modal>
                )}
              </div>
            </Col>
          ) : (
            ''
          )}
        </Form>
      </Row>

      {filterTable.length > 0 && (
        <div className="table-grid-bx">
          <Table
            columns={user.role !== 'Student' ? columns : columnsForStudent}
            dataSource={filterTable}
          />
        </div>
      )}
    </>
  )
}

const ModalContent = props => {
  return (
    <>
      <div
        class="modal fade "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {' '}
                {props.modal.modalName}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body modal-text ">{props.modal.modalData}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form.create()(ProficiencyLevel)
